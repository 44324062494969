import { removeAttribute, setAttribute } from 'lib/dom.js'
import { off, on } from 'lib/events.js'

const TRANSITION_CLASS = 'transition-fade'
const TRANSITION_OUT_CLASS = 'transition-fade-out'

export const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches

export function fadeIn(element, callback = null) {
	if (prefersReducedMotion) {
		removeAttribute(element, 'hidden')
	} else {
		element.classList.add(TRANSITION_CLASS, TRANSITION_OUT_CLASS)

		const toElementTransitionHandler = (previousTransitionEvent) => {
			if (previousTransitionEvent.propertyName === 'opacity') {
				off(element, 'transitionend', toElementTransitionHandler)

				element.classList.remove(TRANSITION_CLASS)

				if (callback) {
					callback.call(null)
				}
			}
		}

		removeAttribute(element, 'hidden')
		element.offsetHeight /* Forces a browser reflow so our animation will work. */

		on(element, 'transitionend', toElementTransitionHandler, { passive: true })

		/*
		 * Removing the fade "out" just brings the opacity back to 1, effectively
		 * fading it in.
		 */
		element.classList.remove(TRANSITION_OUT_CLASS)
	}
}

export function fadeOut(element, callback = null) {
	if (prefersReducedMotion) {
		setAttribute(element, 'hidden', '')
	} else {
		element.classList.add(TRANSITION_CLASS)

		const fromElementTransitionHandler = (transitionEvent) => {
			if (transitionEvent.propertyName === 'opacity') {
				off(element, 'transitionend', fromElementTransitionHandler)

				setAttribute(element, 'hidden', '')
				element.classList.remove(TRANSITION_CLASS, TRANSITION_OUT_CLASS)

				if (callback) {
					callback.call(null)
				}
			}
		}

		on(element, 'transitionend', fromElementTransitionHandler, { passive: true })
		element.classList.add(TRANSITION_OUT_CLASS)
	}
}

/*
 * Transition from one visible element to another, hidden element, making that
 * hidden element visible.
 *
 * See assets/styles/partials/_transitions.scss for what these classes do.
 */
export function swap(fromElement, toElement, callback = null) {
	if (prefersReducedMotion) {
		setAttribute(fromElement, 'hidden', '')
		removeAttribute(toElement, 'hidden')

		if (callback) {
			callback.call(null)
		}
	} else {
		setAttribute(toElement, 'hidden', '')

		fadeOut(fromElement, _ => {
			fadeIn(toElement, callback)
		})
	}
}
